body {
  background-color: #f4f1ff;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
}

.App {
  text-align: center;
}

h1 {
  color: #FFFFFF;
  font-weight: bold;
}

.content.page0 h1 {
  margin-bottom: 60px;
}

.content.page0.small h1 {
  margin-bottom: 20px;
}

.content {
  margin: 20px auto 0px;
  width: 750px;
  background-image: linear-gradient(to bottom right, #8D5AFF, #6236FF);
  border-radius: 20px;
  padding: 30px;
}

.content.page3 {
  padding: 20px;
}

.content p {
  font-size: 16px;
  color: #FFFFFF;
}

video, canvas {
  width: 640px;
  height: 480px;
}

video, #canvas {
  transform: rotateY(180deg);
  -webkit-transform:rotateY(180deg);
  -moz-transform:rotateY(180deg);
}

canvas {
  position: absolute;
  left: calc(50% - 320px);
}

button {
  background-color: #00CFBB;
  color: white;
  font-weight: bold;
  font-size: 22px;
  padding: 10px 20px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #66E2D6;
}

button:active {
  background-color: #00A595;
}

button:focus {
  outline: none;
}

.content.page0 button{
  margin-top: 40px;
}



h2 {
  color: #FFFFFF;
  font-weight: bold;
}

.content.page1 {
  padding: 30px 40px;
}

.content.page1 h2 {
  margin-bottom: 30px;
}

.content.page1 .description {
  color: #FFFFFF;
  line-height: 30px;
  text-align: left;
}

.content.page1 img {
  width: 100%;
}

.content.page1 button {
  margin-top: 30px;
}


.content.page2 {
  padding: 30px 60px;
}

.content.page2 h2 {
  margin-bottom: 30px;
}

.content.page2 .description {
  color: #FFFFFF;
  line-height: 30px;
}

.object-list {
  width: 410px;
  margin: 20px auto;
  height: 350px;
  overflow: auto;
  color: #FFFFFF;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #6236FF; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background:#8D5AFF; 
}

.object-list .row {
  width: 400px;
}

.content.page2 .col-3 {
  margin: 10px auto;
}

.content.page3 h2 {
  margin-bottom: 20px;
}

.content.page3 button {
  margin-top: 20px;
}

.list {
  position: absolute;
  margin-left: 25px;
}

.list-btn {
  position: absolute;
  background: #6236FF; 
  padding: 5px 10px;
  color: #FFFFFF;
  width: 120px;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #FFFFFF;
  display: inline-block;
  margin-left: 5px;
}

.list .object-list {
  width: 130px;
  height: 330px;
  margin: 34px auto 0px;
}

.list .row {
  background: linear-gradient(to bottom right, #c1a5fd, #8D5AFF);
  width: 120px;
  margin: 0px;
}

.list .col-12 {
  padding: 5px auto;
  border: 0.5px dotted #8D5AFF;
  font-size: 14px;
}

h3 {
  color: #FFFFFF;
}

.icon {
  margin: 5px;
}