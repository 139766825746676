.MainPage {
  max-width: 900px;
  margin-top: 30px;

  h1 {
    color:  #8D5AFF;
    margin-left: 20px;
    margin-bottom: 50px;
  }

  .type {
    width: 300px;
    height: 400px;
    background-image: linear-gradient(to bottom right, #8D5AFF, #6236FF);
    border-radius: 10px;
    margin: 20px;
    padding: 30px;
    text-align: center;
    cursor: pointer;

    .title {
      color: white;
      font-weight: bold;
      font-size: 30px;
      margin-bottom: 50px;
    }

    .description {
      p {
        color: white;
        font-weight: bold;
        font-size: 20px;
      }
    }
  }
}

