.Mask {
  text-align: center;

  video {
    transform: rotateY(0deg);
    -webkit-transform:rotateY(0deg);
    -moz-transform:rotateY(0deg);
  }

  .page1 {
    .oval{
      button{
        margin-left: 5px;
      margin-right: 5px;
      }
    }
  }

  .page2 { /* train */
    color: white;
    .row {
      width: 50%;
      margin: 30px auto 10px;

      .label {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 20px;
        height: 40px;
        padding-top: 7px;
        cursor: pointer;

        &.current {
          font-weight: bold;
          background-color: rgba(255, 255, 255, 0.3);
        }

        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }

    .collect {
      margin: 20px;
    }

    .stop {
      margin: 20px;
    }

    .train {
      margin-top: 20px;
      float: right;
    }
  }

  .page3 {
    .faceDiv {
      margin: auto;

      video, canvas {
        transform: rotateY(180deg);
      }
    }

    .loading { 
      color: white;
    }

    .result {
      color: white;
    }
  }

  .page4 {
    padding: 30px 50px;
    .description {
      margin-top: 20px;
      color: white;

      a {
        color: white;
      }

      img {
        width: 100%;
      }

      .row {
        margin: 20px auto;
      }

      .width-50 {
        width: 50%;
      }
    }
  }
}

.faceDiv {
  width: 640px;
  height: 480px;
}

#testdrawing canvas {
  width: auto;
  height: auto;
}

// #faceCanvas {
//   top: 0px;
// }

// #faceVideo {
//   transform: rotateY(180deg);
// }