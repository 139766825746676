.Camera.container {
    max-width: 90%;
    .type{
        height: 170px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        .title{
            margin-bottom: 0px;
        }
    }
  }