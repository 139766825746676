.Emotion {
  text-align: center;

  video, #faceCanvas {
    transform: rotateY(180deg);
    -webkit-transform:rotateY(180deg);
    -moz-transform:rotateY(180deg);
  }

  .page1 {
    .result {
      width: 250px;
      position: absolute;
      color: white;
      padding: 20px;
      top: 170px;
      right: calc(50% - 600px);

      .label, .prob {
        display: inline-block;
      }

      .label {
        background: lighten(#676767, 25%);
        
        border-radius: 30px;
        height: 40px;
        width: 110px;
        padding-top:8px;
        margin: 5px;
        &.current {
          background-color: #676767;
          font-weight: bold;
          padding-top: 7px;
        }
      }

      .prob {
        background-color: lightgray;
        color: black;
        width: 50px;
        height: 40px;
        padding-top: 8px;
        border-radius: 30px;
      }
    }
  }

  .page3 {
    .faceDiv {
      margin: auto;
      
    }
    .results {
      color: white;
    }
  }
}

.faceDiv {
  width: 640px;
  height: 480px;
}

#testdrawing canvas {
  width: auto;
  height: auto;
}

// #faceCanvas {
//   top: 0px;
// }

// #faceVideo {
//   transform: rotateY(180deg);
// }