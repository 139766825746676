.FaceWithBlockly {
  .loadingOverlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.8);

    div {
      color: white;
      font-size: 60px;
    }
  }

  .faceDiv {
    position: absolute;
    width: 320px;
    height: 240px;
    top: 0px;
    right: 0px;

    video {
      width: 320px;
      height: 240px;
    }
    canvas {
      width: 320px;
      height: 240px;
      left: auto;
      right: 0px;
      transform: rotateY(180deg);
      -webkit-transform:rotateY(180deg);
      -moz-transform:rotateY(180deg);
    }
  }

  #label {
    position: absolute;
    top: 210px;
    right: 330px;
  }
}