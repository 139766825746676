.Posenet {
  text-align: center;
  color: #FFFFFF;

  .page0 {
    h1 {
      margin-bottom: 60px;
    }

    button {
      margin-top: 40px;
    }
  }

  .page1 {
    padding: 30px 40px;

    h2 {
      margin-bottom: 30px;
    }

    .description {
      width: 640px;
      margin: auto;
    }
    button {
      margin-top: 20px;
    }
  }

  .curriculum {
    padding: 30px 60px;

    h2 {
      margin-bottom: 30px;
    }

    .description {
      color: #FFFFFF;
      line-height: 30px;
      text-align: left;
    }

    .col-3 {
      margin: 10px auto;
    }

    .teachable-machine-example {
      width: 100%;
    }

    .brick-breaker-img {
      width: 50%;
      margin: 10px auto 20px;
    }

    button {
      margin: auto 10px;
    }

    .examples {
      margin: 20px auto;
      p {
        font-size: 14px;
      }
      img {
        width: 100%;
      }
    }

    .right-example {
      transform: rotateY(180deg);
    }

    .description.center {
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .page3 {
    padding: 20px;

    h2 {
      margin-bottom: 20px;
    }

    canvas {
      width: 480px;
      height: 480px;
      position: absolute;
      left: calc(50% - 240px);
    }

    .result {
      width: 640px;
      margin: auto;

      .label {
        color: white;
        font-size: 18px;
        background-color: rgba(0, 0, 0, 0.3);
        width: 110px;
        height: 40px;
        border-radius: 20px;
        padding-top: 5px;
        margin: 10px auto auto;
        &.current {
          background-color: rgba(255, 255, 255, 0.3);
        }
      }

      .prob {
        font-size: 18px;
        color: #000000;
        background-color: white;
        width: 75px;
        height: 40px;
        border-radius: 20px;
        padding-top: 5px;
        margin: 10px auto auto;
      }
    }

    button {
      margin-top: 20px;
    }
  }

  .page4 {
    video {
      display: none;
    }

    #canvas {
      position: absolute;
      top: 100px;
      left: calc(50% - 680px);
      width: 200px;
      height: 200px;
    }

    .speed {
      color: black;
      margin: 20px auto;
    }

    .results {
      width: 200px;
      position: absolute;
      top: 310px;
      left: calc(50% - 660px);

      .result {
        margin: 5px auto;

        .label {
          color: white;
          font-size: 18px;
          background-color: rgba(0, 0, 0, 0.3);
          width: 100px;
          height: 40px;
          border-radius: 20px;
          padding-top: 5px;
          margin-right: 10px;
          &.current {
            background-color: rgba(0, 0, 0, 0.6);
          }
        }

        .prob {
          font-size: 14px;
          color: #000000;
          background-color: white;
          width: 50px;
          height: 40px;
          border-radius: 20px;
          padding-top: 8px;
        }
      }
    }
  }

  .page5 {
    padding: 30px 60px;

    h2 {
      margin-bottom: 30px;
    }

    .description {
      text-align: left;
    }

    button {
      margin-top: 30px;
    }
  }

  // .page5 {
  //   a {
  //     color: #FFFFFF;
  //     display: block;
  //     margin: 10px auto 20px;
  //   }

  //   p.zero {
  //     margin-bottom: 0px;
  //   }
    
  //   img {
  //     margin-top: 20px;
  //   }
    
  //   .arrow-back {
  //     margin-right: 10px;
  //     cursor: pointer;
  //   }
    
  //   .arrow-next {
  //     transform: rotateY(180deg);
  //     margin-left: 10px;
  //     cursor: pointer;
  //   }

  //   .width-90 {
  //     width: 90%;
  //   }
  // }

  .page6 {
    h2 {
      margin-bottom: 50px;
    }

    input {
      width: 100%;
      text-align: center;
      margin: 10px auto 30px;
      border-radius: 20px;
      border: 0;
      height: 30px;
    }

    .important {
      font-weight: bold;
    }
  }

  .page9 {
    .description {
      margin: 20px auto auto;
      width: 560px;
    }
    .row {
      width: 50%;
      margin: 30px auto 10px;

      .label {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 20px;
        height: 40px;
        padding-top: 7px;
        cursor: pointer;

        &.current {
          font-weight: bold;
          background-color: rgba(255, 255, 255, 0.3);
        }

        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }

    .collect {
      font-size: 16px;
      margin-top: 20px;

      &:disabled {
        opacity: 0.5;
        cursor: auto;
      }
    }

    .train {
      height: 40px;
      button {
        font-size: 18px;
        &.right {
          float: right;
        }
        &.left {
          float: left;
        }
        &.disabled {
          opacity: 0.5;
          cursor: auto;
        }
      }
    }

    .webcam {
      height: 320px;
      video, canvas {
        width: 320px;
        height: 320px;
      }
  
      canvas {
        left: calc(50% - 160px);
      }
    }
  }

  .page10 {
    h2 {
      width: 300px;
      margin: 0px auto 10px;

      span {
        display: inline-block;
        &:first-child {
          width: 68%;
          text-align: right;
        }
        &:last-child {
          width: 32%;
          text-align: left;
        }

      }
    }
  }

  .page11 {
    h2 {
      margin-bottom: 50px;
    }

    button {
      margin: auto 20px;
    }
  }

  .page12, .page13 {
    h2 {
      margin-bottom: 30px;
    }

    .score {
      float: right;
      display: inline;
      margin-left: -90px;
    }

    .question {
      text-align: left;
      width: 600px;
      margin: auto;
      margin-bottom: 20px;
    }

    .answers {
      width: 600px;
      margin: auto auto 40px;
      .answer {
        text-align: left;
        margin: 10px auto;
        .checkbox {
          width: 12px;
          height: 12px;
          background-color: #FFFFFF;
          display: inline-block;
          margin-right: 10px;
          &.checked {
            background-color: black;
          }
        }
        .txt {
          display: inline-block;
        }

        &.correct {
          .checkbox {
            background-color: #00CFBB;
          }
          .txt {
            color: #00CFBB;
          }
        }
        &.wrong {
          .checkbox {
            background-color: red;
          }
          .txt {
            color: red;
          }
        }
      }
    }
  }

  #canvas {
    transform: rotateY(0deg);
    -webkit-transform:rotateY(0deg);
    -moz-transform:rotateY(0deg);
  }

  #gameCanvas {
    margin-top: 10px;
    width: 800px;
    height: 600px;
    position: relative;
    left: 0;
  }

  .label.current {
    background-color: rgba(255, 255, 255, 0.3);
  }
}